
import { Component, Prop, Vue } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import DataTable from '@/components/DataTable/index.vue'
import Creative from '@/models/Creative'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'

@Component({
  components: {
    DataTable,
  },
})
export default class AssociatedItemsTable extends ViewModel {
  @Prop()
  public creativeModel!: Creative

  @Prop({ default: [] })
  public parentQuery!: any

  public query: any = []

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public page_size: number = 10

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public ready: boolean = false

  public fields: any = [
    {
      key: 'owner_type',
      label: 'Owner Type',
      sortable: false,
      show: false,
      filter: false,
    },
    {
      key: 'owner_name',
      label: 'Associated',
      sortable: false,
      show: true,
      filter: false,
    },
  ]

  public fieldFilters: any = {}

  public filter_mode: string = 'exclusive'

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public filterQuery() {
    return this.parentQuery.filter((f: any) => f.includes('associated'))
  }

  public async loadAssociated(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()
    return this.creativeModel
      .loadAssociated({
        page_size: context.perPage,
        page: context.currentPage,
        order_by: context.sortBy,
        order: context.sortDesc ? 'desc' : 'asc',
        query: [...context.filter, ...field_filters, ...this.filterQuery()],
        mode: this.filter_mode,
      })
      .then(result => {
        this.records = result.records
        this.loading = false
        return result.creatives
      })
  }

  public mounted() {
    this.clearFilters()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'creatives-associated',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('creatives-associated').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    this.filter_mode = 'exclusive'
    system.updateState({
      name: 'filters',
      type: 'creatives-associated',
      data: null,
    })
    this.ready = true
  }

  public getFormatedInfo(item: any) {
    let type_formatted = item.owner_type.replaceAll('App\\Models\\', '').toLowerCase()
    let names: any = {
      order: {
        name: item.order_name,
        path: `/app/order/order_overview/${item.owner_id}`,
      },
      lineitem: {
        name: item.line_item_name,
        path: `/app/line-item/${item.owner_id}`,
      },
    }

    return {
      ...names[type_formatted],
      type: type_formatted,
    }
  }
}
