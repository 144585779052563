import Creative from '@/models/Creative'
import { AdserverTypes } from '@/models/interface/Common'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'

const { user } = getModule(SystemtModule)

export default [
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
  },
  {
    key: 'advertiser_id',
    label: 'Advertiser',
    sortable: true,
    class: 'text-center',
    show: true,
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    class: 'text-center',
    show: true,
    filter_type: 'picker',
    options: [
      {
        name: 'Type',
        value: '',
      },
      {
        name: 'Redirect (VAST tag)',
        value: 'vast',
      },
      {
        name: 'Video',
        value: 'video',
      },
    ],
  },
  {
    key: 'duration',
    label: 'Duration',
    sortable: true,
    class: 'text-center',
    show: true,
  },
  {
    key: 'isci_code',
    label: 'ISCI Code',
    sortable: true,
    class: 'text-center',
    show: false,
  },
  {
    key: 'adserver',
    label: 'Adserver',
    type: 'badge',
    sortable: true,
    class: 'text-center',
    show: true,
    filter_type: 'picker',
    options: [
      {
        name: 'Adserver',
        value: '',
      },
      ...AdserverTypes,
    ],
  },
  {
    key: 'ready',
    label: 'Status',
    sortable: true,
    class: 'text-center',
    show: true,
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    class: 'text-center',
    show: true,
  },
  {
    key: 'updated_at',
    label: 'Update At',
    sortable: true,
    class: 'text-center',
    show: false,
  },
  {
    key: 'action',
    type: 'action_list',
    tdClass: 'actions',
    thStyle: { width: '200px' },
    show: true,
    actions: [
      {
        icon: 'pencil',
        title: 'Edit',
        event: 'edit',
        top_level: true,
        show: () => user && user.can('creative', 'edit'),
      },
      {
        icon: 'eye',
        title: 'Preview Creative',
        event: 'preview',
        top_level: true,
      },
      {
        icon: 'code',
        title: 'Inspect',
        event: 'inspect',
        top_level: true,
        action: (creative: Creative) => {
          creative.inspect()
        },
      },
      {
        icon: 'play',
        title: 'Enable Creative',
        event: 'enable',
        show: (creative: Creative) => user && user.can('creative', 'edit') && !creative.enabled,
        action: (creative: Creative) => {
          creative.toggleStatus()
        },
      },
      {
        icon: 'pause',
        title: 'Disable Creative',
        event: 'disable',
        show: (creative: Creative) => user && user.can('creative', 'edit') && creative.enabled,
        action: (creative: Creative) => {
          creative.toggleStatus()
        },
      },
      {
        icon: 'unlink',
        title: 'Remove Creative from all Line Items',
        event: 'unlink',
        show: () => user && user.can('creative', 'edit'),
        action: (creative: Creative) => {
          creative.unlink()
        },
      },

      {
        icon: 'network-wired',
        title: 'View Associated Creatives',
        event: 'load_associated_creatives',
        show: () => user && user.can('creative', 'edit'),
        action: (creative: Creative) => {
          creative._showDetails = !creative._showDetails
        },
      },
    ],
  },
]
